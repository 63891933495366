<template>
<GeneralTermsLayout>
    <div class="row" v-if="status ==='READING_PRIVACY'">
        <div class="col-12">
            <h1>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI</h1>
            <p>
                I dati personali forniti saranno oggetto di trattamento in conformità alle disposizioni di cui Regolamento UE 2016/679 (“GDPR”), in particolare, nel rispetto dei principi di necessità, trasparenza, liceità, correttezza e proporzionalità del trattamento dei dati personali.
            </p>
            <h4 class="my-2">
                1. Il titolare del trattamento
            </h4>
            <p>
               Il Titolare del trattamento dei dati è la società Vysio S.r.l., in persona del suo legale rappresentante pro tempore, con sede legale in Via Carlo Magno n. 17 – 73042 Casarano (LE), P. IVA [05156040759], R.E.A. Lecce LE - 346110: esso decide in modo autonomo sulle finalità e sulle modalità del trattamento, nonché sulle procedure di sicurezza da applicare per garantire la riservatezza, l’integrità e la disponibilità dei dati. L’elenco aggiornato dei Responsabili e degli Addetti al trattamento è custodito presso la sede del Titolare del trattamento.  
            </p>
            <p>
                Il Titolare ha designato, ai sensi degli art. 37 e ss. GDPR, il Responsabile della Protezione dei dati personali, i cui dati di contatto sono: Dott. Marco Seclì – TELEFONO FISSO; indirizzo@mail.it. A costui sono fornite dal Titolare del trattamento tutte le informazioni e le strumentazioni necessarie per assolvere ad ogni obbligo di legge. I Suoi dati personali potranno essere esaminati e/o trattati dai suddetti soggetti che sono vincolati al Titolare dai medesimi obblighi di riservatezza previsti dal presente documento e dalla legge. 
            </p>
            <h4 class="my-2">
                2. Oggetto del trattamento
            </h4>
            <p>
                Il Titolare tratta i dati personali, identificativi e non sensibili (in particolare, nome, cognome, codice fiscale, p. iva, e-mail, numero telefonico, … – in seguito, “dati personali” o anche “dati”) da Lei comunicati in occasione dell’acquisto dei Prodotti di Vysio S.r.l., per le finalità dettagliate all’art. 4 della presente Informativa.
            </p>
            <h4 class="my-2">
                3. Modalità e durata del trattamento
            </h4>
            <p>
                Il trattamento dei Suoi dati personali è realizzato per mezzo delle operazioni indicate all’art. 4 par. 2 GDPR e nel rispetto dei principi di liceità, correttezza e trasparenza e degli altri principi indicati all’art. 5 GDPR.  
            </p>
            <p>
                Il Titolare tratterà i dati per finalità di servizio o contrattuali per il tempo necessario per adempiere alle finalità del trattamento e comunque per non oltre 10 anni dalla cessazione del rapporto per le finalità di servizio, contrattuali, fiscali e contabili.
            </p>
            <p>
                I dati per le finalità di marketing/newsletter saranno conservati per il tempo necessario a perseguire le finalità del trattamento o nel termine inferiore qualora intervenga la revoca del consenso specifico da parte dell’interessato.
            </p>
            <h4 class="my-2">
                4. Finalità del trattamento e base giuridica del trattamento; natura obbligatoria o facoltativa del conferimento dei dati e necessità del consenso e conseguenze del mancato consenso; processi decisionali automatizzati.
            </h4>
            <p>
                I dati personali raccolti verranno trattati in via manuale e/o con il supporto di mezzi informatici o telematici per le seguenti finalità:
            </p>
            <ol type="A">
                <li>
                    per le seguenti finalità primarie di servizio e contrattuali:
                    <ul>
                        <li>
                            per adempiere, prima e dopo l’esecuzione del contratto, a Sue specifiche richieste;
                        </li>
                        <li>
                            per la gestione ed esecuzione degli obblighi derivanti dal contratto del quale Lei è parte; per l’amministrazione della clientela; per il controllo della soddisfazione del cliente; per finalità statistiche e per implementazione nel sistema automatizzato di gestione della clientela; per l’amministrazione di contratti, ordini, spedizioni, bolle e fatture; per l’assistenza post-vendita correlata alla prestazione del servizio/prodotto);
                        </li>
                        <li>
                            adempimento di obblighi di legge di natura amministrativa, contabile, civilistica, fiscale, regolamenti, normative comunitarie ed extracomunitarie, quali ad esempio contabilità, redazione bilancio, tenuta libri sociali obbligatori;
                        </li>
                        <li>
                            gestione del contenzioso (inadempimenti contrattuali, diffide, transazioni, recupero crediti, arbitrati, controversie giudiziarie).
                        </li>
                    </ul>
                    Per le suddette finalità il trattamento dei dati è necessario i) per l'esecuzione degli obblighi derivanti dal contratto con il Cliente e/o per eventuali obblighi precontrattuali adottati su richiesta dello stesso; ii) per adempiere a un obbligo legale al quale è soggetto il Titolare e, infine, iii) per il perseguimento del legittimo interesse del Titolare, quale la gestione del contezioso, il controllo della soddisfazione del cliente, per finalità statistiche e di gestione della clientela. 
                </li>
                <li>
                    solo previo Suo libero ed espresso consenso (art. 7 GDPR), operato mediante specifica approvazione, per le seguenti Finalità di Marketing Diretto tramite invio di e-mail:
                    <ul>
                        <li>
                            compimento ricerche di mercato;
                        </li>
                        <li>
                            invio di materiale pubblicitario e di vendita diretta di prodotti e/o servizi;
                        </li>
                        <li>
                            invio di comunicazioni commerciali.
                        </li>
                    </ul>
                </li>
            </ol>
            <p>
                In merito alle finalità di cui alla lettera B, si comunica che i Suoi dati saranno inseriti in archivi del Titolare ed utilizzati per l’invio di comunicazioni tecniche commerciali o promozionali, per la vendita diretta di prodotti e/o servizi o per il compimento di ricerche di mercato. In particolare, saranno utilizzati per inviare informazioni e comunicazioni concernenti i servizi tecnici e commerciali, i servizi a valore aggiunto, i servizi interattivi pre e post-vendita, novità e promozioni del Titolare.
            </p>
            <p>
                Il conferimento dei Suoi dati per le finalità di cui alla lettera A è obbligatorio e necessario per l’esecuzione del contratto; pertanto, il rifiuto al conferimento degli stessi comporta l’impossibilità di instaurare ed eseguire il rapporto contrattuale.
            </p>
            <p>
                Il conferimento dei Suoi dati per le finalità di cui alla lettera B è facoltativo. Può quindi decidere di non conferire alcun dato o di revocare successivamente e in qualsiasi momento il consenso a trattare dati già forniti, ma la revoca non pregiudicherà la liceità del trattamento basata sul consenso prima della revoca.
            </p>
            <p>
                Le segnaliamo che se è già nostro cliente, potremo inviarLe comunicazioni commerciali relative a servizi e prodotti del Titolare analoghi a quelli di cui ha già usufruito, salvo Suo dissenso.
            </p>
            <p>
                Il trattamento dei Suoi dati non sarà in alcun modo soggetto a profilazione e/o decisioni automatizzate.
            </p>
            <h4 class="my-2">
                5. Accesso e trasferimento dei dati
            </h4>
            <p>
                I Suoi dati verranno trattati con l’ausilio di strumenti informatici e manuali idonei a garantirne la sicurezza e riservatezza, e non saranno diffusi.
            </p>
            <p>
                I Suoi dati potranno essere resi accessibili e/o trasferiti per le finalità di cui al punto 4:
            </p>
            <ul>
                <li>
                    a dipendenti e/o collaboratori del Titolare nominati Addetti al trattamento;
                </li>
                <li>
                    a soggetti esterni al Titolare all’uopo nominati Responsabili o Sub-Responsabili del trattamento; 
                </li>
                <li>
                    a terzi soggetti (ad esempio, provider per la gestione e manutenzione del sito web, fornitori, istituti di credito, studi professionali, etc) che dovessero svolgere attività in outsourcing per conto del Titolare, nella loro qualità di Responsabili del trattamento, nonché alle competenti autorità (es. Prefettura, Questura, Forze dell’Ordine, Carabinieri, Vigili del Fuoco), enti pubblici e/o privati per gli adempimenti di legge. 
                </li>
            </ul>
            <p>
                La gestione e la conservazione dei dati personali avverrà su archivi cartacei presso la sede del Titolare e/o su server ubicati all’interno dell’Unione Europea di proprietà del Titolare e/o di società terze incaricate e debitamente nominate quali Responsabili del trattamento. Resta in ogni caso inteso che il Titolare, ove si rendesse necessario, avrà facoltà di spostare l’ubicazione dei server in Italia e/o Unione Europea e/o Paesi extra-UE. In tal caso, il Titolare assicura sin d’ora che il trasferimento avverrà in conformità alle disposizioni di legge applicabili stipulando, se necessario, accordi che garantiscano un livello di protezione adeguato e/o adottando le clausole contrattuali standard previste dalla Commissione Europea.
            </p>
            <h4 class="my-2">
                6. Diritti dell’interessato
            </h4>
            <p>
                Lei può esercitare i diritti di cui ali artt. da 15 a 22 GDPR, in particolare il diritto di accesso ai dati, di rettifica o di cancellazione degli stessi, di richiedere la limitazione o di opporsi al trattamento, oltre al diritto alla portabilità dei dati. L’interessato ha, inoltre, il diritto di proporre reclamo all’Autorità Garante.
                <br/>
                In caso di violazione dei Suoi dati personali, tenuto conto di quanto previsto dall’art. 34 GDPR, il Titolare provvederà a comunicarLe tale violazione.
            </p>
            <h4 class="my-2">
                7. Contatti per l’esercizio dei diritti dell’interessato
            </h4>
            <p>
                Lei in qualsiasi momento può esercitare i diritti inviando una raccomandata A/R all’indirizzo della sede legale del Titolare del Trattamento o una e-mail all’indirizzo indirizzo@mail.it.
            </p>
            <hr/>
            <p>
                Il sottoscritto dichiara di aver letto l’informativa che precede, rilasciata dal Titolare ai sensi dell’art. 13 del GDPR ed
            </p>
            <form>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="privacyRadio" id="privacyRadio-accept" value="accept">
                    <label class="form-check-label" for="privacyRadio-accept">acconsente</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="privacyRadio" id="privacyRadio-deny" value="deny">
                    <label class="form-check-label" for="privacyRadio-deny">non acconsente</label>
                </div>
            </form>
            <p>
                all’utilizzo dei propri dati personali da parte del Titolare per il compimento di ricerche di mercato, l’invio di newsletter e comunicazioni promozionali ai fini di marketing diretto attraverso e-mail (finalità di cui all’art. 4 lettera B).
            </p>
        </div>
        <div class="col-12 my-4 text-center">
            <button class="btn btn-primary" @click="onPrivacyAccepted()">
                Accetta e procedi
            </button>
        </div>
    </div>
    <div class="row" v-else-if="status === 'ACCEPTED_OK'">
        <div class="col-12">
            <div class="alert alert-success">
                <h3>Informativa privacy accettata!</h3>
                <p>
                   Grazie per aver accettato la nostra informativa privacy. Ora puoi utilizzare il tuo sistema Adapta Easy!
                </p>
            </div>
        </div>
        <div class="col-12">
            <router-link class="btn btn-primary" to="/auth">Procedi</router-link>
        </div>
    </div>
    
    <div class="row" v-else-if="status === 'ACCEPTED_ERROR'">
        <div class="col-12">
            <div class="alert alert-danger">
                <h3>Errore!</h3>
                <p>
                    Siamo spiacenti, non è stato possibile esegure l'operazione a causa di un errore.
                </p>
            </div>
        </div>
        <div class="col-12">
            <button class="btn btn-primary" @click="retryOnError()">
                Riprova
            </button>
        </div>
    </div>
</GeneralTermsLayout>    
</template>

<script>
import GeneralTermsLayout from '@/views/layouts/general-terms.vue';
import API from '@/api.js';

export default {
    name: 'PrivacyTerms',
    components: {
        GeneralTermsLayout,
    },
    data() {
        return {
            user: this.$auth.user(),
            status: this.$auth.user().store.privacy_terms_accepted === true ? 'ACCEPTED_OK' : 'READING_PRIVACY',
        }
    },
    methods: {
        async onPrivacyAccepted() {
            // Accept license and proceed
            try {
                const r = await this.axios.put(API.acceptPrivacy.path(this.user.store.id), {
                    privacy_terms_accepted: true,
                });
                if( r.status === 200) {
                    this.$auth.fetchUser();
                    this.status = 'ACCEPTED_OK';
                } else {
                    this.status = 'ACCEPTED_ERROR';
                }
            } catch (e) {
                this.status = 'ACCEPTED_ERROR';
            }
        },
        retryOnError() {
            this.status = 'READING_PRIVACY';
        },
    },
}
</script>
